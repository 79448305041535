import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading';

function Callback() {
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const redirect_uri = `${document.location.origin}/callback`;
        if (state === sessionStorage.getItem('oauthState') && code) {
            axios.post('/api/token', {
                code,
                redirect_uri
            }).then((response) => {
                sessionStorage.setItem('token', response.data.token);
                console.log(response.data.token);
                navigate('/');
                window.location.reload();
            });
        }
    }, [navigate]);

    return (
        <div>
            <Loading />
        </div>
    );
}

export default Callback;