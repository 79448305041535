import React from 'react';
import { Container, Typography } from '@mui/material';

const About = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '75vh' }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          This is an app that allows you to create a Spotify playlist from songs recently played on radio stations.
          <br/>
          <br/>
          Right now we support all the iheart and audacy stations, in addition to a few we just like.
          <br/>
          <br/>
          If there is a station you like that's not on here, or have any other feedback, please let us know! 
          <br/>
          <br/>
          We are still in development mode so in order to gain access please email us using the same email you use for Spotify and we will add you to the beta. 25 people max for now.
          <br/>
          <br/>
          You can reach us at <a href="mailto:support@radiosun.day" target="_blank" rel="noreferrer">support@radiosun.day</a>
        </Typography>
      </Container>
    </div>
  );
};

export default About;