import React from 'react';
import { Box, Container, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 3,  position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>
      <Container maxWidth="md">
        <Typography variant="body2" color="text.secondary" align="center">
          RadioSunday | <a href="https://patreon.com/RadioSunday">Support us on Patreon</a>
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;