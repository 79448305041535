import React, { useState } from 'react';
import { Autocomplete, TextField, Button, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import './PlaylistForm.css';
import Instructions from './Instructions';
import stationList from './station_list.json';

function PlaylistForm() {
    const [url, setUrl] = useState('');
    const [playlistId, setPlaylistId] = useState('');
    const [uniqueSongsOnly, setUniqueSongsOnly] = useState(false);
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(true);
    const [error, setError] = useState('');

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    const handleAutocompleteChange = (event, value) => {
        if (value) {
            setUrl(value);
        }
    };

    const handlePlaylistIdChange = (event) => {
        setPlaylistId(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setUniqueSongsOnly(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Make a call to the backend with the form data
        const token = sessionStorage.getItem('token');
        if(token === null) {
            setError('You must login with Spotify first.');
            setShowError(true);
            return;
        }
        if(stationList[url] === undefined) {
            setError('You must select a valid radio station.');
            setShowError(true);
            return;
        }

        fetch('/api/playlist', {
            method: 'POST',
            body: JSON.stringify({ url: stationList[url], playlistId, unique_only : uniqueSongsOnly, token }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            if(data?.error && data?.error !== '') {
                console.log('Returned Error:', data.error)
                setError(data.error);
                setShowError(true);
                return;
            }
            window.location.href = 'https://open.spotify.com/playlist/' + data.playlistId;
        })
        .catch((error) => {
            console.log('Error:', error.message)
            setError(error.message);
            setShowError(true);
          });
    };

    const handleClose = () => {
        setShowError(false);
    };

    return (
        <div className="container">
            <form className="input-form-container" onSubmit={handleSubmit}>
            <Autocomplete
                freeSolo
                options={Object.keys(stationList)}
                value={url}
                onChange={handleAutocompleteChange}
                renderInput={params => (
                    <TextField
                      {...params}
                      id="url"
                      label="Radio Station"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={url}
                      onChange={handleUrlChange}
                      required
                    />
                  )}
                />
                <TextField
                    id="playlist-id"
                    label="Playlist Id"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={playlistId}
                    onChange={handlePlaylistIdChange}
                    required={false}
                />
                <FormControlLabel
                    control={<Checkbox checked={uniqueSongsOnly} onChange={handleCheckboxChange} />}
                    label="Unique Songs Only"
                />
                <Button type="submit" variant="contained" color="primary" size="large">
                    Submit
                </Button>
                <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
                    Instructions
                </Button>
            </form>
            <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose} message={error} />
            <Instructions open={open} setOpen={setOpen} />
        </div>
    );
}

export default PlaylistForm;