import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

function Instructions({open, setOpen}) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Instructions</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select your favorite radio station from the dropdown list.
                    <br/>
                    You may begin typing the name of the radio station to search the list.
                    <br/>
                    <br/>
                    If you want to have these added to a specific playlist, add the ID of the playlist you want to add it to. 
                    <br/>
                    You can find this in the spotify playlist url, the id will be at the end https://open.spotify.com/playlist/(playlist_id)
                    <br/>
                    If left blank a new playlist will be created.
                    <br/>
                    <br/>
                    Select "Only include unique songs" to remove any duplicate songs from the playlist.
                    <br/>
                    <br/>
                    It may take a couple minutes for the playlist to be populated.
                    <br/>
                    <br/>
                    Please support your favorite radio stations outside of using this app.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default Instructions;