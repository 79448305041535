import React from 'react';
import { Button } from '@mui/material';

const config = {
  authorizationEndpoint: 'https://accounts.spotify.com/authorize',
  tokenEndpoint: '/api/token',
  clientId: '5e6af30ed5ff4edb88815a942bd8cfbc',
  redirectUri: `${document.location.origin}/callback`,
  scopes: ['playlist-modify-public', 'playlist-modify-private'],
};
 
function Login() {
    const login = () => {
        const state = Math.random().toString(36).substring(7);
        const params = new URLSearchParams({
        response_type: 'code',
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        scope: config.scopes.join(' '),
        state: state
        });
        
        sessionStorage.setItem('oauthState', state);

        window.location.href = `${config.authorizationEndpoint}?${params}`;
    };
     
    return (
        <div>
            <Button variant="contained" color="primary" onClick={login}>
                Login with Spotify
            </Button>
        </div>
    );
}

export default Login;