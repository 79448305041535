import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import PlaylistForm from '../components/PlaylistForm';
import Login from '../components/Login';

const Home = () => {
  const [showPlaylistForm, setShowPlaylistForm] = useState(false);

  const handleClick = () => {
    setShowPlaylistForm(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '75vh' }}>
      {sessionStorage.getItem('token') === null ? (
        showPlaylistForm ? (
          <div>
            <Typography variant="h4" gutterBottom align="center">
              Please log into spotify to continue
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Login />
            </div>
          </div>
        ) : (
          <div>
            <Typography variant="h4" gutterBottom align="center">
              Welcome to Radio Sunday!
            </Typography>
            <Typography variant="body1" gutterBottom align="center">
              To get started, click the "Get started!" button below to create a new playlist.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Get started!
              </Button>
            </div>
          </div>
        )
      ) : (
        <PlaylistForm />
      )}
    </div>
  );
};

export default Home;