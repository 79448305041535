import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';


function Navbar() {
  const handleLogout = () => {
    sessionStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <AppBar position="static">
      <Toolbar>
      <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" height="80" width="80" />
        <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', ml: 1  }}>
          Home
        </Typography>
        <Button component={Link} to="/about" color="inherit">About</Button>
        {sessionStorage.getItem('token') !== null && (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;